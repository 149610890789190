/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



// mat-form-field{
//     width: 100%;

// }
// mat-card {
//     width: 80%;
//     margin: 50px auto;
//     padding: 20px;
// }

.ol-unselectable {
    border-radius: 20px;
}

.mat-tab-label .mat-tab-label-content {
    color: #8B0621;
} 
